import styles from '../../Header.module.css';
import MenuDesktopWithSubMenu from '../../shared/MenuDesktopWithSubMenu';
import { MenuItem } from '../../shared/MenuItem';
import { DesktopCustomerSupportProps } from '../../types';

const DesktopCustomerSupport: React.FC<DesktopCustomerSupportProps> = ({
  textColor,
  isCustom,
  attendanceHeaderLink,
}) => {
  return (
    <MenuDesktopWithSubMenu
      title={attendanceHeaderLink?.title}
      key={`header-link-${attendanceHeaderLink?.title}`}
      textColor={textColor}
      isCustom={isCustom}
    >
      <div className={`${styles.menu} right-0 mb-32 !pt-0`}>
        {attendanceHeaderLink?.subItems?.map((subItem, index) => (
          <div
            key={index}
            className={`${index > 0 ? 'border-t border-display-200' : ''} `}
          >
            <MenuItem
              key={index}
              {...subItem}
              containerClassName="pt-24 pb-8"
              classNameSubtitle="paragraph-16 !font-semibold !text-display-900"
              className="!my-4 lg:hover:bg-display-100"
            />
          </div>
        ))}
      </div>
    </MenuDesktopWithSubMenu>
  );
};

export default DesktopCustomerSupport;
