import Link from 'next/link';

import { Choose } from '@/components/UtilitiesComponents';

import styles from '../../Header.module.css';
import MenuDesktopWithSubMenu from '../../shared/MenuDesktopWithSubMenu';
import { MenuItem } from '../../shared/MenuItem';
import { ProductsMenuProps } from './';

export const HeaderLinksWithoutProducts: React.FC<ProductsMenuProps> = ({
  headerLinksWithoutProducts,
  isCustom,
  textColor,
}) => (
  <>
    {headerLinksWithoutProducts?.map((link, index) => (
      <Choose key={index}>
        <Choose.When condition={Boolean(link.href)}>
          <Link
            href={link.href ?? ''}
            prefetch={false}
            aria-label={link.title}
            className={`px-16 my-auto font-medium ${textColor} hover:text-stone-500 paragraph-16`}
          >
            {link.title}
          </Link>
        </Choose.When>
        <Choose.Otherwise>
          <MenuDesktopWithSubMenu
            title={link.title}
            textColor={textColor}
            isCustom={isCustom}
          >
            <div className={styles.menu}>
              <div>
                <MenuItem
                  pageLink={link.pageLink}
                  className="p-4 !my-0 lg:hover:bg-display-100"
                  classNameTitle="paragraph-16"
                  classNameSubtitle="paragraph-14 !text-display-900"
                />
              </div>
            </div>
          </MenuDesktopWithSubMenu>
        </Choose.Otherwise>
      </Choose>
    ))}
  </>
);
