'use client';

import Image from 'next/image';
import Link from 'next/link';
import QRCodeImage from 'react-qr-code';

import { If } from '@/components/UtilitiesComponents';
import LINKS from '@/constants/links';

import styles from '../../Header.module.css';

interface DownloadAppProps {
  useMenuStyle?: boolean;
  utmUrl: string;
}

export const DownloadApp: React.FC<DownloadAppProps> = ({
  useMenuStyle = true,
  utmUrl,
}) => (
  <div className={`${useMenuStyle ? styles.menu : ''} right-0 py-24`}>
    <div className="flex flex-col items-center p-16">
      <p className="mb-8 font-semibold">
        Escaneie com a câmera do seu celular:
      </p>
      <div className="hidden md:flex pt-16">
        <If condition={Boolean(utmUrl)}>
          <QRCodeImage size={160} value={utmUrl} />
        </If>
      </div>
    </div>
    <hr className="my-8 h-1 bg-display-200" />
    <div className="flex flex-col items-center p-16">
      <p className="mb-8 font-semibold">Ou acesse abaixo:</p>
      <DownloadLinks />
    </div>
  </div>
);

const DownloadLinks: React.FC = () => (
  <div className="flex gap-8">
    <Link href={LINKS.APP_LINK_ANDROID} target="_blank" rel="noreferrer">
      <Image
        src="/site-stone/brands/googlePlay"
        alt="Disponível no Google Play"
        width={152}
        height={44}
      />
    </Link>
    <Link href={LINKS.APP_LINK_IOS} target="_blank" rel="noreferrer">
      <Image
        src="/site-stone/brands/appStore"
        alt="Disponível na App Store"
        width={152}
        height={44}
      />
    </Link>
  </div>
);
