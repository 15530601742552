'use client';

import LINKS from '@/constants/links';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';
import { useEffect, useState } from 'react';

interface Props {
  customQRCodeLink?: string;
}
export function DownloadAppMobile({ customQRCodeLink }: Props) {
  const [adjustLink, setAdjustLink] = useState(
    customQRCodeLink || LINKS.APP_LINK,
  );
  const [utmUrl, setUtmUrl] = useState(
    customQRCodeLink || LINKS.APP_LINK_QR_CODE,
  );

  useEffect(() => {
    setAdjustLink(utmUrl);
  }, [utmUrl]);

  useEffect(() => {
    setUtmUrl(handleAppsflyerUtmUrl(customQRCodeLink));
  }, [customQRCodeLink]);

  return (
    <a
      className="px-16 my-auto font-medium !text-display-900 paragraph-16"
      href={adjustLink}
    >
      Baixe o App
    </a>
  );
}
