'use client';
// TODO(@gabrieldissotti) não usar use client pra tudo... isolar o que precisar.

/* eslint-disable tailwindcss/no-custom-classname */
import classNames from 'classnames';
import React, { useRef } from 'react';

import { DesktopMenu } from './components/DesktopMenu';
import MenuMobile from './components/MobileMenu';
import { HeaderProps } from './types';

export function Header({
  headerStickyOnMobile = true,
  background,
  ...props
}: HeaderProps) {
  const meuElementoRef = useRef<HTMLDivElement>(null);

  return (
    <header
      ref={meuElementoRef}
      id="Context_menu"
      className={classNames(
        `top-0 z-40 py-8 px-16 h-[64px] ${
          background ?? 'bg-display-0 border-b border-display-200'
        }`,
        {
          'sticky lg:relative': headerStickyOnMobile,
        },
      )}
    >
      <DesktopMenu {...props} />
      <MenuMobile {...props} menuRef={meuElementoRef} />
    </header>
  );
}
