'use client';
import { If } from '@/components/UtilitiesComponents';
import Icon, { IconsNames } from '@/components/v3/Icon';

export type MenuItemWithIconAndTextsProps = {
  text: string;
  description?: string;
  smallDescription?: string;
  iconName: {
    name: IconsNames;
  };
  href?: string;
  onClick?: () => void;
};
const MenuItemWithIconAndTexts: React.FC<MenuItemWithIconAndTextsProps> = ({
  text,
  description,
  iconName,
  smallDescription,
  href,
  onClick,
}) => (
  <a
    href={href}
    onClick={onClick}
    className="flex items-center p-16 mx-[-16px] hover:text-stone-500 lg:hover:bg-display-50"
    aria-label={text}
  >
    <Icon
      name={iconName.name}
      className={`w-24 min-w-[24px] h-24 ${
        iconName.name !== 'libras' && 'fill-current'
      }`}
    />
    <p className="flex flex-col ml-16">
      <span className="font-semibold">{text}</span>
      <If condition={Boolean(description)}>
        <span className="mt-2 font-semibold">{description}</span>
      </If>

      <If condition={Boolean(smallDescription)}>
        <span className="mt-2 paragraph-14">{smallDescription}</span>
      </If>
    </p>
  </a>
);
export default MenuItemWithIconAndTexts;
