'use client';

import React, { useCallback, useEffect, useRef } from 'react';

import { listenClickOnElementBasedOnRef } from '@/utils/listenClickOnElementBasedOnRef';

import ClientOnlyPortal from '../ClientOnlyPortal';

interface IModal {
  open: boolean;
  onCloseClick(): void;
  children: React.ReactNode;
  closeOnClickOut?: boolean;
  renderOnOpen?: boolean;
  className?: string;
}

const Modal: React.FC<IModal> = ({
  open,
  children,
  onCloseClick,
  closeOnClickOut = true,
  renderOnOpen,
  className,
}) => {
  const modalContainerRef = useRef(null);

  const modalEventListener = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) =>
      listenClickOnElementBasedOnRef(
        modalContainerRef,
        ({ clickOnElement }) => {
          if (open && clickOnElement) {
            onCloseClick();
          }
        },
      )(event),
    [open, onCloseClick, modalContainerRef],
  );

  useEffect(() => {
    if (closeOnClickOut && open) {
      window.addEventListener('click', modalEventListener);
    }

    return () => {
      if (closeOnClickOut && open) {
        window.removeEventListener('click', modalEventListener);
      }
    };
  }, [open, closeOnClickOut, modalEventListener]);

  if (renderOnOpen && !open) {
    return null;
  }

  return (
    <ClientOnlyPortal selector="#modal">
      <div
        ref={modalContainerRef}
        className={`fixed bg-display-900 bg-opacity-75 px-16 md:px-0 bg-op top-0 left-0 bottom-0 right-0 z-[1000] flex items-center justify-center ${
          !open && 'hidden'
        }`}
      >
        <div
          className={`flex overflow-auto md:overflow-visible md:p-24 py-16 px-24 bg-display-0 rounded ${className}`}
        >
          {children}
        </div>
      </div>
    </ClientOnlyPortal>
  );
};

export default React.memo(Modal);
