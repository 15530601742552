import { MenuItemWithIconAndTextsProps } from '@/app/components/sections/Header/shared/MenuItemWithIconAndTexts';
import {
  HeaderLink,
  HeaderProps,
  MenuProps,
} from '@/app/components/sections/Header/types';
import { IconsNames } from '@/components/v3/Icon';
import LINKS from '@/constants/links';
import {
  handlePhoneOnInboundHeaderClick,
  handlePhoneOnRCHeaderClick,
  handleWppOnRCHeaderClick,
} from '@/utils/Analytics';

export const menuData: MenuProps = {
  headerProducts: {
    title: 'Produtos',
    subtitle: 'Soluções de venda pro seu negócio',
    iconName: { id: 1, name: 'pos-small' },
    sectionMenu: [
      {
        title: 'Pro seu negócio',

        pageLink: [
          {
            title: 'Maquininha',
            subtitle: 'A máquina de cartão que facilita o seu dia a dia',
            iconName: { name: 'pos' },
            href: '/maquina-de-cartao',
          },
          {
            title: 'Conta PJ Stone',
            subtitle:
              'Conta PJ gratuita, sem burocracia e feita pro seu negócio',
            iconName: { name: 'cash-phone' },
            href: '/conta-pj',
          },
          {
            title: 'Capital de Giro Stone',
            subtitle: 'Empréstimo fácil de receber, fácil de pagar',
            iconName: { name: 'round-dollar' },
            href: '/capital-de-giro',
          },
          {
            title: 'Giro Fácil',
            subtitle: 'Crédito rápido pro seu caixa girar',
            iconName: { name: 'money-hand' },
            href: '/giro-facil',
          },
          {
            title: 'Venda no WhatsApp',
            subtitle: 'Venda online com a Stone pelo seu WhatsApp',
            iconName: { name: 'logo-whatsapp' },
            href: '/venda-no-whatsapp',
          },
          {
            title: 'Seguro de Loja',
            subtitle: 'Proteção pro seu negócio por menos de R$2 por dia',
            iconName: { name: 'life-buoy' },
            href: '/seguro-de-loja',
          },
          {
            title: 'Seguro de Vida',
            subtitle: 'Pra você e sua família ficarem  protegidos',
            iconName: { name: 'hand-heart' },
            href: '/seguro-de-vida/',
          },
        ],
      },
      {
        title: 'Facilite sua rotina',
        pageLink: [
          {
            title: 'Cartão Stone',
            subtitle: 'Cartão internacional grátis pra compras e saques',
            iconName: { name: 'card-stone' },
            href: '/cartao-de-credito',
          },
          {
            title: 'Link de Pagamento',
            subtitle: 'Venda pela internet e nas redes sociais',
            iconName: { name: 'payment-link' },
            href: '/link-de-pagamento',
          },
          {
            title: 'Reserva Stone',
            subtitle: 'Guarde dinheiro, crie metas e retire quando quiser',
            iconName: { name: 'pig' },
            href: '/reserva-stone',
          },
          {
            title: 'Pix',
            subtitle: 'Conheça todas as formas de vender com QR Code',
            iconName: { name: 'qr-code' },
            href: '/pix',
          },
          {
            title: 'Antecipação',
            subtitle: 'Receba suas vendas quando quiser ',
            iconName: { name: 'cent-clock' },
            href: '/antecipacao',
          },
          {
            title: 'Controle',
            subtitle: 'Gerencie as suas vendas de onde estiver',
            iconName: { name: 'b-chart-small' },
            href: '/gestao',
          },
        ],
      },
    ],
    sectionMenuRight: [
      {
        title: 'Pra você que é autônomo',
        pageLink: [
          {
            title: 'Ton',
            subtitle: 'Ton é da Stone pro autônomo',
            iconName: { name: 'pos-small' },
            href: 'https://ton.com.br/',
          },
        ],
      },
      {
        title: 'Mais soluções',
        pageLink: [
          {
            title: 'E-commerce',
            subtitle: 'Pagar.me, pra você que já tem uma loja virtual',
            iconName: { name: 'cart' },
            href: '/solucoes-de-pagamentos-digitais',
          },
          {
            title: 'Raio X',
            subtitle: 'Acompanhe todas suas maquininhas e taxas em um só lugar',
            iconName: { name: 'raio-x' },
            href: '/gestao/#raio-x',
          },
        ],
      },
    ],
  },
  headerLinksWithoutProducts: [
    {
      title: 'Planos',
      subtitle: 'Planos pensados pro seu tipo de negócio',
      href: '/planos/',
      iconName: { name: 'round-dollar' },
      pageLink: [],
    },
    {
      title: 'Sobre a Stone',
      subtitle: 'Conheça mais sobre nossa empresa',
      iconName: { name: 'stone' },
      pageLink: [
        {
          title: 'Conheça a Stone',
          subtitle: 'Tudo pra vender, gerir e girar',
          iconName: { name: 'people-handshake' },
          href: '/produtos-stone/',
        },
        {
          title: 'Por que Stone',
          subtitle: 'Um novo modo de botar pra girar',
          iconName: { name: 'people-question' },
          href: '/modostone/',
        },
        {
          title: 'Nossa história',
          subtitle: 'Conheça nossa história e propósito',
          iconName: { name: 'file-typed' },
          href: '/nossa-historia/',
        },

        {
          title: 'Stone e Linx',
          subtitle: 'Controle de vendas e recebimentos',
          iconName: { name: 'rings' },
          href: '/stone-linx/',
        },
        {
          title: 'Programa de Parcerias',
          subtitle: 'Seu próximo passo começa aqui',
          iconName: { name: 'handshake' },
          href: 'https://partner.stone.com.br/',
        },
        {
          title: 'Trabalhe conosco',
          subtitle: 'Transforme seu potencial em potência',
          iconName: { name: 'users' },
          href: 'https://jornada.stone.com.br/',
        },
        {
          title: 'Seja um franqueado',
          subtitle: 'Se torne dono da Stone na sua cidade',
          iconName: { name: 'stone-agent' },
          href: '/franquias/',
        },
      ],
    },
    {
      title: 'Blog',
      subtitle: 'Artigos e vídeos para o empreendedor',
      href: LINKS.BLOG,
      iconName: { name: 'papo-de-balcao' },
      pageLink: [],
    },
  ],
};
export const headerData: HeaderProps = {
  isButtonMobile: true,
  isCustomerSupportMobile: true,
  isProductsMenuMobile: true,
  isProductsMenuDesktop: true,
  isCustomerSupportDesktop: true,
  isButtonDesktop: true,
  isDownloadApp: true,
  isLogin: true,
};
export const attendanceHeaderLink: HeaderLink = {
  title: 'Atendimento',
  iconName: { name: 'phone', id: 1 },
  subItems: [
    {
      title: 'Ainda não sou cliente',
      pageLink: [
        {
          title: 'Adquira pelo telefone',
          subtitle: '3004-9681',
          subtitleBold: true,
          iconName: { name: 'phone' },
          href: LINKS.NOT_CLIENT_PHONE,
          handleClick: () => handlePhoneOnInboundHeaderClick(),
        },
        {
          title: 'Adquira pelo WhatsApp',
          iconName: { name: 'whatsapp' },
          href: LINKS.NOT_CLIENT_WHATSAPP,
        },
      ],
    },
    {
      title: 'Já sou cliente',
      pageLink: [
        {
          title: 'Atendimento por telefone',
          subtitle: '3004-9680',
          subtitleBold: true,
          iconName: { name: 'phone' },
          href: LINKS.CLIENT_PHONE,
          handleClick: () => handlePhoneOnRCHeaderClick(),
        },
        {
          title: 'Atendimento por WhatsApp',
          iconName: { name: 'whatsapp' },
          href: LINKS.CLIENT_WHATSAPP,
          handleClick: () => handleWppOnRCHeaderClick(),
        },
        {
          title: 'Atendimento em libras',
          iconName: { name: 'pounds' },
          href: LINKS.CLIENT_LIBRAS,
        },
        {
          title: 'Central de Ajuda',
          iconName: { name: 'life-buoy' },
          href: LINKS.HELP_CENTER,
        },
      ],
    },
    {
      pageLink: [
        {
          title: 'Entre em contato',
          iconName: { name: 'customer-support' },
          href: '/suporte-stone/',
        },
      ],
    },
  ],
};

export const nonClientItems: MenuItemWithIconAndTextsProps[] = [
  {
    iconName: {
      name: 'phone',
    },
    text: 'Adquira pelo Telefone',
    description: '3004-9681',
    href: LINKS.NOT_CLIENT_PHONE,
    onClick: handlePhoneOnInboundHeaderClick,
  },
  {
    iconName: {
      name: 'whatsapp',
    },
    text: 'Adquira pelo WhatsApp',
    href: LINKS.NOT_CLIENT_WHATSAPP,
  },
];

export const clientItems: MenuItemWithIconAndTextsProps[] = [
  {
    iconName: {
      name: 'life-buoy',
    },
    text: 'Central de ajuda',
    smallDescription:
      'Tire todas as suas dúvidas com nossos artigos, vídeos e tutoriais',
    href: LINKS.HELP_CENTER,
  },
  {
    iconName: {
      name: 'phone',
    },
    text: 'Atendimento pelo telefone',
    description: '3004-9680',
    href: LINKS.CLIENT_PHONE,
    onClick: handlePhoneOnRCHeaderClick,
  },
  {
    iconName: {
      name: 'whatsapp',
    },
    text: 'Atendimento pelo WhatsApp',
    href: LINKS.CLIENT_WHATSAPP,
    onClick: handleWppOnRCHeaderClick,
  },
  {
    iconName: {
      name: 'libras',
    },
    text: 'Atendimento em libras',
    href: LINKS.CLIENT_LIBRAS,
  },
];

export const clientWithNoIssues = [
  {
    href: LINKS.NOT_CLIENT_PHONE,
    onclick: handlePhoneOnInboundHeaderClick,
    iconName: {
      name: 'phone' as IconsNames,
    },
    text: 'Telefone',
  },
  {
    href: LINKS.NOT_CLIENT_WHATSAPP,
    iconName: {
      name: 'whatsapp' as IconsNames,
    },
    text: 'Whatsapp',
  },
];

export const clientStone = [
  {
    href: LINKS.CLIENT_PHONE,
    onclick: handlePhoneOnRCHeaderClick,
    iconName: {
      name: 'phone' as IconsNames,
    },
    text: 'Telefone',
  },
  {
    href: LINKS.CLIENT_WHATSAPP,
    onclick: handleWppOnRCHeaderClick,
    iconName: {
      name: 'whatsapp' as IconsNames,
    },
    text: 'Whatsapp',
  },
];
