'use client';

import React, { useCallback, useEffect, useState } from 'react';

import { If } from '@/components/UtilitiesComponents';
import Icon from '@/components/v3/Icon';
import { useInboundDrawer } from '@/contexts/inboundDrawer';
import { menuData } from '@/resources/header';

import styles from '../../Header.module.css';
import BeStoneButton from '../../shared/BeStoneButton';
import LogoStone from '../../shared/LogoStone';
import { HeaderProps } from '../../types';
import MobileContactOptionsModal from '../MobileContactOptionsModal';
import MobileProductsMenuModal from '../MobileProductsMenuModal';
import { DownloadAppMobile } from '../DownloadAppMobile';

interface HeaderMenu extends HeaderProps {
  showDrawer?: boolean;
  menuRef: React.RefObject<HTMLDivElement>;
}

const MenuMobile: React.FC<HeaderMenu> = ({
  showDrawer = false,
  styledStoneButton,
  colorLogo,
  iconMenu,
  customQRCodeLink,
  isButtonMobile,
  isCustomerSupportMobile,
  isProductsMenuMobile,
  isDownloadAppMobile,
  menuRef,
}) => {
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [openContactMenu, setOpenContactMenu] = useState(false);
  const { toggleDrawer } = useInboundDrawer();

  const [menuHeight, setMenuHeight] = useState(120);

  const toggleDrawerHandler = useCallback(() => {
    setOpenMenuMobile(false);
    setOpenContactMenu(false);
    toggleDrawer();
  }, [toggleDrawer]);

  const toggleMenuMobile = useCallback(() => {
    setOpenMenuMobile(prev => !prev);
    setOpenContactMenu(false);
  }, []);

  const toggleContactMenu = useCallback(() => {
    setOpenContactMenu(prev => !prev);
    setOpenMenuMobile(false);
  }, []);

  useEffect(() => {
    const el = document.getElementById('__next');
    if (el) {
      el.style.overflow = openMenuMobile ? 'hidden' : '';
    }
  }, [openMenuMobile]);

  useEffect(() => {
    const handleResize = () => {
      if (menuRef.current) {
        setMenuHeight(menuRef.current.offsetHeight + menuRef.current.offsetTop);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [menuRef]);

  return (
    <div className="flex lg:hidden justify-between items-center h-full">
      <LogoStone colorLogo={colorLogo} />
      <div className="flex my-auto ml-auto">
        <If condition={Boolean(isButtonMobile)}>
          <div className="w-[124px]">
            <BeStoneButton
              onClick={showDrawer ? toggleDrawerHandler : undefined}
              className={styledStoneButton}
            />
          </div>
        </If>

        <div className={styles.menuMobileRightIcons}>
          <If condition={Boolean(isCustomerSupportMobile)}>
            <button
              type="button"
              onClick={toggleContactMenu}
              className={openContactMenu ? styles.active : ''}
              title="Suporte para o cliente"
              aria-label="Suporte para o cliente"
            >
              <Icon
                name="customer-support"
                className={`fill-current ${iconMenu}`}
              />
            </button>
          </If>

          <If condition={Boolean(isProductsMenuMobile)}>
            <button
              type="button"
              title="Menu mobile"
              aria-label="Menu mobile"
              onClick={toggleMenuMobile}
              className={`${iconMenu ? styles.menuIcon2 : styles.menuIcon} ${
                openMenuMobile ? styles.active : ''
              }`}
            >
              <span />
              <span />
              <span />
            </button>
          </If>

          <If condition={Boolean(isDownloadAppMobile)}>
            <DownloadAppMobile customQRCodeLink={customQRCodeLink} />
          </If>
        </div>
      </div>

      <MobileProductsMenuModal
        openMenuMobile={openMenuMobile}
        menuHeight={menuHeight}
        customQRCodeLink={customQRCodeLink}
        showDrawer={showDrawer}
        handleToggleDrawer={toggleDrawerHandler}
        {...menuData}
      />
      <MobileContactOptionsModal
        menuHeight={menuHeight}
        openContactMenu={openContactMenu}
      />
    </div>
  );
};

export default MenuMobile;
