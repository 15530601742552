'use client';

import { m } from 'framer-motion';
import { FC } from 'react';

import Icon from '@/components/v3/Icon';
import { clientStone, clientWithNoIssues } from '@/resources/header';

import styles from '../../Header.module.css';
import { menuMobileDrawerVariants, menuMobileVariants } from '../../helpers';

const MobileContactOptionsModal: FC<{
  menuHeight?: number;
  openContactMenu?: boolean;
}> = ({ openContactMenu, menuHeight }) => {
  return (
    <m.div
      animate={openContactMenu ? 'open' : 'closed'}
      variants={menuMobileDrawerVariants}
      className={`${styles.customerMenuDrawer} xs:bg-opacity/50 z-10`}
      style={{ top: `${menuHeight}px` }}
      initial="closed"
    >
      <m.div
        animate={openContactMenu ? 'open' : 'closed'}
        variants={menuMobileVariants}
        className={styles.menuMobile}
        transition={{ ease: 'linear' }}
      >
        <div className="flex flex-col gap-12 items-center p-16 bg-display-0">
          <p className="font-bold">Quero ser cliente Stone</p>
          <div className="flex gap-12 w-full">
            {clientWithNoIssues.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="px-0 w-1/2 btn btn-primary btn-large"
                onClick={item?.onclick}
              >
                <p className="flex text-white">
                  <Icon
                    name={item.iconName.name}
                    className="mr-8 text-white fill-current"
                  />
                  {item.text}
                </p>
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-12 items-center p-16 bg-display-0">
          <p className="font-bold">Já sou cliente Stone</p>
          <div className="flex gap-12 w-full">
            {clientStone.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="px-0 w-1/2 btn btn-secondary btn-large"
                onClick={item.onclick}
              >
                <p className="flex text-black">
                  <Icon
                    name={item.iconName.name}
                    className="mr-8 text-black fill-current"
                  />
                  {item.text}
                </p>
              </a>
            ))}
          </div>
        </div>
      </m.div>
    </m.div>
  );
};

export default MobileContactOptionsModal;
