import { Variants } from 'framer-motion';

export const menuMobileVariants: Variants = {
  open: {
    translate: 0,
  },
  closed: {
    translateX: '100%',
  },
};

export const menuMobileDrawerVariants: Variants = {
  open: {
    display: 'flex',
    opacity: 1,
  },
  closed: {
    transition: {
      delay: 0.35,
    },
    opacity: 0,
    display: 'none',
  },
};
