import clsx from 'clsx';

import styles from '../../Header.module.css';

const MenuDesktopWithSubMenu: React.FC<{
  title?: string;
  children: React.ReactNode;
  textColor?: string;
  isCustom?: boolean;
}> = ({ title, children, textColor, isCustom }) => (
  <div
    className={clsx(
      isCustom ? styles.customMenuContainer : styles.menuContainer,
      'relative z-10',
    )}
  >
    <div
      aria-label={title}
      className={`px-16 my-auto font-medium ${textColor} paragraph-16`}
    >
      {title}
    </div>
    {children}
    <div className={styles.menuOverlay}></div>
  </div>
);

export default MenuDesktopWithSubMenu;
