'use client';

import { useState } from 'react';

import Modal from '@/components/v3/Modal';
import { clientItems, nonClientItems } from '@/resources/header';

import styles from '../../Header.module.css';
import { DownloadApp } from '../../shared/DownloadAppMenu/DownloadApp';
import MenuItemWithIconAndTexts, {
  MenuItemWithIconAndTextsProps,
} from '../../shared/MenuItemWithIconAndTexts';

const MobileCustomerSupport: React.FC<{
  utmUrl: string;
}> = ({ utmUrl }) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => setShowModal(false);

  const renderMenuItems = (items: MenuItemWithIconAndTextsProps[]) =>
    items.map(
      (
        { iconName, text, description, smallDescription, href, onClick },
        index,
      ) => (
        <MenuItemWithIconAndTexts
          key={index}
          iconName={iconName}
          text={text}
          description={description}
          smallDescription={smallDescription}
          href={href}
          onClick={onClick}
        />
      ),
    );

  return (
    <div className={`attendance` || `${styles.menu} right-0 py-24`}>
      <Modal open={showModal} closeOnClickOut onCloseClick={closeModal}>
        <DownloadApp utmUrl={utmUrl} useMenuStyle={false} />
      </Modal>
      <div className="px-16 pt-16">
        <p className="mb-8 font-bold">Ainda não sou cliente</p>
        {renderMenuItems(nonClientItems)}
      </div>
      <div className="px-16 pt-16 border-t border-display-200">
        <p className="mb-8 font-bold">Já sou cliente</p>
        {renderMenuItems(clientItems)}
      </div>
      <div className="px-16 border-t border-display-200">
        <MenuItemWithIconAndTexts
          iconName={{
            name: 'customer-support',
          }}
          text="Entre em contato"
          smallDescription="Atendimento humano, sempre que você precisar"
          href="/suporte-stone/"
        />
      </div>
    </div>
  );
};

export default MobileCustomerSupport;
