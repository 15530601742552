import Image from 'next/image';
import Link from 'next/link';

import Icon from '@/components/v3/Icon';

const MachineImage = () => {
  return (
    <Link href="/planos/" passHref prefetch={false}>
      <div className="group flex lg:flex-col md:gap-0 justify-start p-16 md:p-0 lg:m-0 mx-16 mb-24 md:!w-[468px] lg:!w-[258px] xl:!w-[290px] align-middle bg-display-100 rounded-md hover:cursor-pointer">
        <div className="relative md:w-[217px] lg:w-[258px] xl:w-[290px] min-w-[136px] lg:!h-[150px] min-h-[88px]">
          <div className="block md:hidden lg:block">
            <Image
              src="site-stone/menu/solucao-completa"
              layout="fill"
              alt="Conheça nossos planos"
              className="!h-[stretch] rounded-[8px] md:rounded-md lg:rounded-t-md md:rounded-r-none lg:rounded-b-none"
            />
          </div>
          <div className="hidden md:block lg:hidden">
            <Image
              src="site-stone/menu/solucao-completa-2"
              layout="fill"
              alt="Conheça nossos planos"
              className="!h-[stretch] rounded-md rounded-r-none"
            />
          </div>
        </div>

        <div className="flex md:flex-col md:p-16">
          <div className="hidden lg:flex flex-col bg-display-100 lg:rounded-md">
            <p className="pb-8 font-display group-hover:!text-stone-500 heading-6">
              Escolha o Plano Stone que mais combina com o seu negócio
            </p>
            <p className="hidden lg:block paragraph-14">
              Maquininha sem aluguel, conta grátis e cartão sem mensalidade
            </p>
          </div>

          <p className="lg:hidden self-center pl-16 md:pl-0 md:w-[219px] font-display font-bold leading-[22px] group-hover:text-stone-500 md:group-hover:text-display-900 md:heading-6">
            Maquininha sem aluguel e Planos que não pesam nas despesas
          </p>

          <p className="flex justify-center items-center pt-8 lg:pt-16 pl-0 w-max font-semibold text-stone-500 group-hover:text-stone-700">
            <span className="hidden md:flex items-center border border-transparent group-hover:border-b-stone-700">
              <span className="mr-4 font-medium text-current paragraph-16">
                Conheça nossos Planos
              </span>
              <Icon
                name="chevron-right"
                className="mx-auto w-24 h-24 fill-current"
              />
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default MachineImage;
