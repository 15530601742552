'use client';

import { useEffect, useState } from 'react';

import LINKS from '@/constants/links';
import { handleAppsflyerUtmUrl } from '@/utils/handleAppsflyerUtmUrl';

import MenuDesktopWithSubMenu from '../MenuDesktopWithSubMenu';
import { DownloadApp } from './DownloadApp';

export interface DownloadAppMenuProps {
  textColor?: string;
  isCustom?: boolean;
  customQRCodeLink?: string;
}

export const DownloadAppMenu: React.FC<DownloadAppMenuProps> = ({
  textColor,
  isCustom,
  customQRCodeLink = LINKS.APP_LINK_APPSFLYER_QR_MENU,
}) => {
  const [utmUrl, setUtmUrl] = useState(customQRCodeLink);

  useEffect(() => {
    setUtmUrl(handleAppsflyerUtmUrl(customQRCodeLink));
  }, [customQRCodeLink]);

  return (
    <MenuDesktopWithSubMenu
      title="Baixe o app"
      textColor={textColor}
      isCustom={isCustom}
    >
      <DownloadApp utmUrl={utmUrl} />
    </MenuDesktopWithSubMenu>
  );
};

export default DownloadAppMenu;
