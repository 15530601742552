'use client';

import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import Button from '@/app/components/base/ui/Button';
import LINKS from '@/constants/links';

const BeStoneButton: React.FC<{ className?: string; onClick?: () => void }> = ({
  onClick,
  className,
}) => {
  const [url, setUrl] = useState(LINKS.INBOUND);

  useEffect(() => {
    setUrl(
      window.location.search
        ? LINKS.INBOUND + window.location.search
        : LINKS.INBOUND,
    );
  }, []);

  return onClick ? (
    <Button
      className={`px-16 ${className || 'w-max'}`}
      size="regular"
      onClick={onClick}
      sectionReference="Header"
    >
      Seja Stone
    </Button>
  ) : (
    <Link
      href={url}
      prefetch={false}
      aria-label="Seja Stone"
      className={`btn btn-primary btn-regular px-16 ${className}`}
    >
      Seja Stone
    </Link>
  );
};

export default BeStoneButton;
