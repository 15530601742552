import React from 'react';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expr: any | (() => boolean);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: React.ReactNode | any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const When = (props: Props) => {
  const { expr, children } = props;
  if (typeof expr === 'function' && !expr()) return <></>;
  if (!expr) return <></>;

  return typeof children === 'function' ? children() : children;
};
