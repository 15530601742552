import React from 'react';

import { CustomMenuProps, MenuProps } from '../../types';
import { HeaderLinksWithoutProducts } from './HeaderLinksWithoutProducts';
import { ProductLinkMenu } from './ProductLinkMenu';

export interface ProductsMenuProps extends MenuProps, CustomMenuProps {}

const DesktopProductsMenu: React.FC<ProductsMenuProps> = ({
  isCustom = false,
  textColor = 'text-display-900',
  headerProducts,
  headerLinksWithoutProducts,
}) => {
  return (
    <div
      className="hidden md:flex flex-1 justify-start items-center self-stretch ml-24"
      data-testid="links-container"
    >
      <ProductLinkMenu
        isCustom={isCustom}
        textColor={textColor}
        {...headerProducts}
      />
      <HeaderLinksWithoutProducts
        headerLinksWithoutProducts={headerLinksWithoutProducts}
        isCustom={isCustom}
        textColor={textColor}
      />
    </div>
  );
};

export default DesktopProductsMenu;
