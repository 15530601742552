'use client';

import { m, Variants } from 'framer-motion';
import React, { useState } from 'react';

import Icon, { IconsNames } from '@/components/v3/Icon';

export type MenuWithSubMenuProps = {
  title: string;
  subtitle?: string;
  iconName?: IconsNames;
  children: React.ReactNode;
};

const subMenuAnimation: Variants = {
  open: {
    opacity: 1,
    maxHeight: 1500,
    transition: {
      duration: 0.6,
      ease: 'easeIn',
    },
  },
  closed: {
    opacity: 0,
    maxHeight: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const MenuWithSubMenu: React.FC<MenuWithSubMenuProps> = ({
  title,
  subtitle,
  iconName,
  children,
}) => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);

  return (
    <div
      className={`w-full ${subMenuIsOpen ? 'bg-display-50' : 'bg-display-0'}`}
    >
      <button
        type="button"
        aria-label={title}
        onClick={() => setSubMenuIsOpen(value => !value)}
        className="flex items-center p-16 my-auto w-full text-display-900"
      >
        {iconName && <Icon name={iconName} className="mr-12 fill-current" />}
        <div className="flex flex-col items-start">
          <p className="font-semibold">{title}</p>
          <p className="font-normal paragraph-14">{subtitle}</p>
        </div>
        <Icon
          name="chevron-down"
          className={`ml-auto fill-current transition-all${
            subMenuIsOpen ? 'rotate-180' : ''
          }`}
        />
      </button>
      <m.div
        className="flex overflow-hidden flex-col px-24 md:px-16 lg:pr-0 bg-display-0"
        animate={subMenuIsOpen ? 'open' : 'closed'}
        variants={subMenuAnimation}
        initial="closed"
      >
        {children}
        <hr className="h-1 bg-display-200" />
      </m.div>
    </div>
  );
};

export default MenuWithSubMenu;
